<template>
<div style="width: 100%; height: 100%;">
  <iframe ref="aboutIframe" src="../../static/about/index.html" width="100%" height="100%" style="border:0px; padding:0px; margin:0px;"></iframe>
</div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
    }
  },
  created () {
    // 监听navbar修改主题
    this.common.eventBus().$on('changeTheme', (data) => {
      this.changeIframeTheme(data.theme)
    })
  },
  mounted () {
    // iframe调用父组件,复制文本到剪贴板
    const that = this
    window.addEventListener('message', function (event) {
      const { action, data } = event.data
      if (action === 'copyToClipboard') {
        that.common.copyToClipboard(data.content)
      }
    })

    // 初始化iframe的主题色
    this.initIframeTheme()
  },
  methods: {
    changeIframeTheme (theme) {
      const aboutIframe = this.$refs.aboutIframe
      const aboutIframeWindow = aboutIframe.contentWindow
      aboutIframeWindow.postMessage({ action: 'changeTheme', data: { theme } }, '*')
    },
    initIframeTheme () {
      const theme = window.localStorage.getItem('vs-theme')

      // 向iframe发布消息
      const aboutIframe = this.$refs.aboutIframe
      aboutIframe.addEventListener('load', () => {
        const aboutIframeWindow = aboutIframe.contentWindow
        aboutIframeWindow.postMessage({ action: 'initTheme', data: { theme } }, '*')
      })
    }
  }
}
</script>

<style scoped>
</style>
